import React, { useState, useEffect } from 'react';

const IntroTextEffect = ({ onComplete }) => {
  const [visible, setVisible] = useState(true);
  const [fading, setFading] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setFading(true);
    }, 4500); // Start fading out after 4.5 seconds

    const visibilityTimer = setTimeout(() => {
      setVisible(false);
      onComplete();
    }, 5000); // Complete fade out after 5 seconds

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(visibilityTimer);
    };
  }, [onComplete]);

  if (!visible) return null;

  return (
    <div className={`intro-effect ${fading ? 'fading' : ''}`}>
      <style jsx>{`
        .intro-effect {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 100;
          background-color: rgba(255, 255, 255, 0.8);
          opacity: 1;
          transition: opacity 0.5s ease-out;
        }
        .intro-effect.fading {
          opacity: 0;
        }
        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
        }
        .intro-text {
          font-weight: bold;
          color: transparent;
          text-shadow: 0 0 5px rgba(0,0,0,0.5);
          -webkit-text-stroke: 2px white;
          margin: 0;
          line-height: 1.2;
          animation: fadeIn 2s ease-in-out;
        }
        .intro-text-main {
          font-size: 18vw;
          margin-bottom: 2vh;
        }
        .intro-text-sub {
          font-size: 14vw;
        }
        @media (min-width: 768px) {
          .intro-text-main {
            font-size: 10vw;
          }
          .intro-text-sub {
            font-size: 8vw;
          }
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
      `}</style>
      <div className="text-container">
        <h1 className="intro-text intro-text-main">Hi Hkm,</h1>
        <h1 className="intro-text intro-text-sub">Kaiso ho?</h1>
      </div>
    </div>
  );
};

export default IntroTextEffect;