import React, { useState, useEffect, useRef } from "react";
import MagicSprinklesBackground from "./components/MagicSprinklesBackground";
import IntroTextEffect from "./components/IntroTextEffect";
import OnboardingOverlay from "./components/OnboardingOverlay";
import GiftBox from "./components/GiftBox";
import { Eye, Image, Gift } from "lucide-react";

const images = [
  { id: 1, src: "images/image-1.webp", alt: "Image 1" },
  { id: 2, src: "images/image-2.webp", alt: "Image 2" },
  { id: 3, src: "images/image-3.webp", alt: "Image 3" },
  { id: 4, src: "images/image-4.webp", alt: "Image 4" },
  { id: 5, src: "images/image-5.webp", alt: "Image 5" },
  { id: 6, src: "images/image-6.webp", alt: "Image 6" },
  { id: 7, src: "images/image-7.webp", alt: "Image 7" },
  { id: 8, src: "images/image-8.webp", alt: "Image 8" },
  { id: 9, src: "images/image-9.webp", alt: "Image 9" },
  { id: 10, src: "images/image-10.webp", alt: "Image 10" },
  { id: 11, src: "images/image-11.webp", alt: "Image 11" },
  { id: 12, src: "images/image-12.webp", alt: "Image 12" },
  { id: 13, src: "images/image-13.webp", alt: "Image 13" },
  { id: 14, src: "images/image-14.webp", alt: "Image 14" },
  { id: 15, src: "images/image-15.webp", alt: "Image 15" },
  { id: 16, src: "images/image-16.webp", alt: "Image 16" },
  { id: 17, src: "images/image-17.webp", alt: "Image 17" },
  { id: 18, src: "images/image-18.webp", alt: "Image 18" },
  { id: 19, src: "images/image-19.webp", alt: "Image 19" },
  { id: 20, src: "images/image-20.webp", alt: "Image 20" },
  { id: 21, src: "images/image-21.webp", alt: "Image 21" },
  { id: 22, src: "images/image-22.webp", alt: "Image 22" },
  { id: 23, src: "images/image-23.webp", alt: "Image 23" },
  { id: 24, src: "images/image-24.webp", alt: "Image 24" },
  { id: 25, src: "images/image-25.webp", alt: "Image 25" },
];

const hiddenMessages = {
  1: "Yeh bichu bel ka flower apki tarah strong aur khoobsurat  hai, jaise thodi si sunshine khil jata hai! apki yeh pic hamesha mujhe apki yaad dilata hai. 🌺",
  2: "🖼️ yeh Ganesh ji ka yeh painting waise hi shanti deta hai jaise unka naam. Har baar dekhta hoon toh apki aap kitni talented ho malum ho jata hai hkm.",
  3: "Kelwa beach ka yeh raasta hamesha yaad dilata hai kitna peaceful aur endless feel hota tha sab kuch. Kabhi saath chalenge yeh view enjoy karne 🤝.",
  4: "Mumbai ka CST station 🚂, Us raat yeh jagah bhot badiya lag rahi thi, na? Kabhi apke saath dekhna hai.",
  5: "1000 saal purana Shivji ka yeh sculpture dekh ke hamesha ek ajeeb si shanti milti hai. Imagine, kitni kahaniyan isne dekhi hongi! 🕉️🙏",
  6: "apko yaad hai aapne yeh magggi mene banai thi to aapne tareef ki thi 😄. mene or bhi try kiya, ab to professional chefs bhi badiya banata hu! 🍜",
  7: "Yeh pyara dog kitna cute lag raha hai! apke jija ke friends ke milne gaye the tab aapne bheja tha 😄🐕",
  8: "Yeh AI version bhi apki asli khubsurti ko capture nahi kar paya 😄. Brother ki shaadi waale look mein aap achi lag rahi thi",
  9: "kitne pyaare lag rahe ho aap iss photo mein! apki smile toh tab bhi waise hi thi, ab tak bilkul same hai 😄.",
  10: "Bhatyanisa wale mandir ka photo aapne kitna accha liya hai. aap apne bhaba or bhabhisa or mummy ke sath gaye the yah? 🙏",
  11: "Eh Laughing Buddha aapne kitne pyare colors se paint kiya hai! Aise hobbies bilkul mat chhodna, yeh aapko unique banati hai.",
  12: "Aapke gaon ka yeh Malinathji ke mandir aap gaye the aapke friends and bhabisa 🛕",
  13: "Yeh bottle toh aapke touch se ekdum art piece ban gayi!, aapke talent ki baat hi alag hai.🎨",
  14: "Mumbai ki rush mein bhi Rajasthani jooti ki simplicity ka apna hi charm hai. Yeh mix kaafi interesting lagta hai, dono worlds ka best combination 😄.",
  15: "Yeh moment hamesha yaad rahega, jab humne ek doosre ko ring pehnayi thi aur apni journey ki shuruaat ki ❤️💍. Aage ka safar aur bhi khoobsurat hoga.",
  16: "Kankaria Talav ka yeh sunset kitna beautiful hai. Yeh jagah perfect hai thoda waqt apun ke saath bitaane ke liye. Agli baar aapke saath jarur aana hai yaha hkm ❤️.",
  17: "Aapke Gurudev jab aapke gaon aaye the, aapki baari aayi nahi thi ki kuch aap apne sawaal puch sako, yaad hai aapko? 😄🙏",
  18: "Hkm, Yeh bachda kitna pyaara hai! Aankhon mein kitni masoomiyat hai. 😄🐄.",
  19: "Dhebu, Dhebi aur teesre ka naam yaad nahi aa raha 😅 par yeh tino milke kitne pyaare lag rahe hain! Inki bonding dekh ke smile aa gayi 🐾😄.",
  20: "Cat me bhot jada curiosity hoti hai. bhot cute lag rahi hai 😄🐱.",
  21: "Yeh dono toh ekdum royally relax kar rahe hain! Chair bhi purani hai aur kittens bhi ekdum classy lag rahe hain 😄🐾. Kitne cute pose diya hai dono ne!",
  22: "Yaad hai jab maine apne pinky toe par thoda corner me lag gaya tha, aur aapne yeh photo apne mummy ko dikha diya 😂? Embarrassing tha, but also affectionate 😊💖.",
  23: "Is year(2024) ka Lok Sabha election ka yeh mark hamesha yaad rahega bhot lambi line thi.🇮🇳🗳️",
  24: "Jaiput, Amer Fort ke top se yeh nazara dekh ke bas ek hi baat sochi kitna sukoon hai yaha. Agli baar apke saath yeh view dekhna hai! 😊🏞️",
  25: "Kumbhalgarh Fort ki yeh walls dekh ke bas ek hi cheez sochi kitni amazing history isme chhupi hai! 😊🏰.",
};

const Gallery = () => {
  const [activeImage, setActiveImage] = useState(null);
  const [activeMessage, setActiveMessage] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [introCompleted, setIntroCompleted] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [viewedImages, setViewedImages] = useState(() => {
    const saved = localStorage.getItem("viewedImages");
    return saved ? JSON.parse(saved) : {};
  });
  const [showGiftBox, setShowGiftBox] = useState(false);
  const [hasClickedImage, setHasClickedImage] = useState(false);
  const [allImagesViewed, setAllImagesViewed] = useState(false);
  const [giftBoxShownOnce, setGiftBoxShownOnce] = useState(() => {
    return localStorage.getItem("giftBoxShownOnce") === "true";
  });
  const modalImageRef = useRef(null);
  const columnRefs = useRef([]);
  const mobileGalleryRef = useRef(null);

  const viewedCount = Object.keys(viewedImages).length;
  const totalImages = images.length;

  useEffect(() => {
    const checkIfDesktop = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    checkIfDesktop();
    window.addEventListener("resize", checkIfDesktop);

    return () => window.removeEventListener("resize", checkIfDesktop);
  }, []);

  useEffect(() => {
    if (introCompleted) {
      const hasSeenOnboarding = localStorage.getItem("hasSeenOnboarding");
      if (!hasSeenOnboarding) {
        setShowOnboarding(true);
      } else {
        setOnboardingCompleted(true);
      }
    }
  }, [introCompleted]);

  useEffect(() => {
    const scroll = () => {
      if (isDesktop) {
        columnRefs.current.forEach((column, index) => {
          const scrollAmount = 1;
          if (index % 2 === 0) {
            column.scrollTop += scrollAmount;
            if (column.scrollTop >= column.scrollHeight / 2) {
              column.scrollTop = 0;
            }
          } else {
            column.scrollTop -= scrollAmount;
            if (column.scrollTop <= 0) {
              column.scrollTop = column.scrollHeight / 2;
            }
          }
        });
      } else if (mobileGalleryRef.current) {
        mobileGalleryRef.current.scrollTop += 1;
        if (
          mobileGalleryRef.current.scrollTop >=
          mobileGalleryRef.current.scrollHeight / 2
        ) {
          mobileGalleryRef.current.scrollTop = 0;
        }
      }
    };

    const scrollInterval = setInterval(scroll, 30);
    return () => clearInterval(scrollInterval);
  }, [isDesktop]);

  useEffect(() => {
    localStorage.setItem("viewedImages", JSON.stringify(viewedImages));
    if (viewedCount === totalImages && !allImagesViewed) {
      setAllImagesViewed(true);
    }
  }, [viewedImages, viewedCount, totalImages, allImagesViewed]);

  const closeModal = () => {
    setActiveImage(null);
    setActiveMessage("");
    if (allImagesViewed && !giftBoxShownOnce) {
      setShowGiftBox(true);
      setGiftBoxShownOnce(true);
      localStorage.setItem("giftBoxShownOnce", "true");
    }
  };

  const handleImageLoad = () => {
    if (modalImageRef.current) {
      modalImageRef.current.style.maxHeight = `calc(100vh - 2rem)`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleImageLoad);
    return () => window.removeEventListener("resize", handleImageLoad);
  }, []);

  const handleImageClick = (image) => {
    setActiveImage(image);
    setActiveMessage(
      hiddenMessages[image.id] || "No hidden message for this image."
    );
    setViewedImages((prev) => ({ ...prev, [image.id]: true }));
    setHasClickedImage(true);
  };

  const handleIntroComplete = () => {
    setShowIntro(false);
    setIntroCompleted(true);
  };

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    setOnboardingCompleted(true);
    localStorage.setItem("hasSeenOnboarding", "true");
  };

  const handleGiftBoxClose = () => {
    setShowGiftBox(false);
  };

  const handleGiftIconClick = () => {
    if (allImagesViewed) {
      setShowGiftBox(true);
    }
  };

  const renderMobileGallery = () => (
    <div className="h-screen overflow-hidden px-2" ref={mobileGalleryRef}>
      <div className="grid grid-cols-2 gap-2 pb-2">
        {[...images, ...images].map((image, index) => (
          <div key={`${image.id}-${index}`} className="aspect-square relative">
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover rounded-lg cursor-pointer"
              onClick={() => handleImageClick(image)}
            />
            {viewedImages[image.id] && (
              <div className="absolute top-2 right-2 bg-black bg-opacity-50 rounded-full p-1">
                <Eye size={20} color="white" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderDesktopGallery = () => (
    <div className="flex space-x-2 overflow-hidden h-screen px-2">
      {[
        images.slice(0, 5),
        images.slice(5, 10),
        images.slice(10, 15),
        images.slice(15, 20),
        images.slice(20, 25),
      ].map((columnImages, columnIndex) => (
        <div
          key={columnIndex}
          className="scroll-column w-1/5 h-full flex flex-col space-y-2 overflow-hidden"
          ref={(el) => (columnRefs.current[columnIndex] = el)}
        >
          {[...columnImages, ...columnImages].map((image, index) => (
            <div
              key={`${image.id}-${index}`}
              className="w-full aspect-square relative"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => handleImageClick(image)}
              />
              <button
                type="button"
                aria-label={`View details of ${image.alt}`}
                className="absolute w-full h-full top-0 left-0 bg-white opacity-0 z-10 transition-opacity duration-300 hover:opacity-20"
                onClick={() => handleImageClick(image)}
              ></button>
              {viewedImages[image.id] && (
                <div className="absolute top-2 right-2 bg-black bg-opacity-50 rounded-full p-1">
                  <Eye size={20} color="white" />
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const renderModal = () => (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 p-4"
      onClick={closeModal}
    >
      <div className="relative max-w-4xl">
        <div className="relative">
          <img
            ref={modalImageRef}
            src={activeImage.src}
            alt={activeImage.alt}
            className="w-full h-auto object-contain rounded-lg"
            onLoad={handleImageLoad}
          />
          <div className="absolute inset-x-0 bottom-0 rounded-b-lg overflow-hidden">
            <div className="bg-black bg-opacity-20 backdrop-filter backdrop-blur-md p-4">
              <p className="text-center text-lg font-semibold text-white">
                {activeMessage}
              </p>
            </div>
          </div>
        </div>
        <button
          className="absolute top-2 right-2 bg-white text-black px-4 py-2 rounded-full shadow-md hover:bg-gray-200 transition-colors"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );

  const renderViewedCount = () => (
    <div className="fixed bottom-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full flex items-center space-x-2 z-40">
      <Eye size={20} />
      <span>{viewedCount}</span>
      <Image size={20} />
      <span>{totalImages}</span>
      <div className="relative group">
        <Gift
          size={20}
          className="text-white cursor-pointer"
          onClick={handleGiftIconClick}
        />
        {!allImagesViewed && (
          <div className="absolute bottom-full right-0 mb-2 hidden group-hover:block z-50">
            <div className="bg-black text-white text-xs rounded py-2 px-3 whitespace-nowrap">
              View all images to get a gift
            </div>
            <div className="absolute top-full right-2 border-4 border-transparent border-t-black"></div>
          </div>
        )}
        {allImagesViewed && (
          <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full"></div>
        )}
      </div>
    </div>
  );

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="absolute inset-0 z-0">
        <MagicSprinklesBackground />
      </div>
      <div className="relative z-10 h-full">
        {isDesktop ? renderDesktopGallery() : renderMobileGallery()}
      </div>
      {activeImage && renderModal()}
      {renderViewedCount()}
      {showIntro && <IntroTextEffect onComplete={handleIntroComplete} />}
      {showOnboarding && <OnboardingOverlay onClose={handleOnboardingClose} />}
      {showGiftBox && <GiftBox onClose={handleGiftBoxClose} />}
    </div>
  );
};

export default Gallery;
