import React, { useState } from 'react';
import { X } from 'lucide-react';

const GiftBox = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  if (isOpen) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
        <div className="relative w-full h-full max-w-4xl max-h-[90vh] flex items-center justify-center">
          <div className="relative">
            <img
              src="images/letter.webp"
              alt="Gift"
              className="max-w-full max-h-[85vh] w-auto h-auto object-contain shadow-lg"
            />
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-white bg-gray-800 bg-opacity-75 hover:bg-opacity-100 rounded-full p-1 transition-colors"
              aria-label="Close"
            >
              <X size={15} />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-2xl shadow-xl max-w-md w-full overflow-hidden">
        <div className="p-6 sm:p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Badhaai ho!!</h2>
          <div className="space-y-4">
            <div className="text-center">
              <div className="mb-4 relative">
                <div className="relative w-32 h-32 mx-auto">
                  <div className="absolute inset-0 bg-gradient-to-br from-yellow-300 to-orange-500 opacity-75 blur-xl rounded-full animate-pulse"></div>
                  <img
                    src="icons/giftbox.webp"
                    alt="Gift Box"
                    className="absolute inset-0 w-full h-full object-contain z-10 animate-float drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]"
                  />
                </div>
              </div>
              <p className="text-lg text-gray-600 leading-relaxed mt-6">
                Aapro gift taiyaar hai.
              </p>
            </div>
            <div className="bg-orange-50 rounded-lg p-4">
              <p className="text-sm text-orange-700">
              👉 Button par click karo aur aapno uphaar kholo!
              </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-6 py-4 sm:px-8 sm:py-6">
          <button
            onClick={handleOpen}
            className="w-full bg-orange-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-orange-700 transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Open Gift
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftBox;