import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const OnboardingOverlay = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const steps = [
    {
      title: "Khamma Ghani sa 🙏🏻",
      message: "Harek photo mein aapne khatar ek khaas sandesh hai, padharo aur photo dekho Hkm!",
      tip: "👉 Koi bhi photo par click karo aur usmein chhupo huvo sandesh jaano!",
      buttonText: "Chalo dekhosa!"
    },
    {
      title: "Ek vaat aur hkm 🎁",
      message: "Jab aap sabhi photo dekhlos ho, to aapne ek chhupo huvo gift milego Hkm!",
      tip: "💯 sabhi photo dekho aur apno gift praapt karosa!",
      buttonText: "Gift milosa!"
    }
  ];

  useEffect(() => {
    const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
    if (!hasSeenOnboarding) {
      setShowOverlay(true);
    }
  }, []);

  const handleClose = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowOverlay(false);
      localStorage.setItem('hasSeenOnboarding', 'true');
    }
  };

  if (!showOverlay) return null;

  const { title, message, tip, buttonText } = steps[currentStep];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-2xl shadow-xl max-w-md w-full overflow-hidden">
        <div className="p-6 sm:p-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
              aria-label="Close"
            >
              <X size={24} />
            </button>
          </div>
          <div className="space-y-4">
            <p className="text-lg text-gray-600 leading-relaxed">{message}</p>
            <div className="bg-orange-50 rounded-lg p-4">
              <p className="text-sm text-orange-700">{tip}</p>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-6 py-4 sm:px-8 sm:py-6">
          <button
            onClick={handleClose}
            className="w-full bg-orange-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-orange-700 transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOverlay;